<template>
  <AccordionRows
    :entities="entities"
    :fields="fieldRows"
    :isPending="currentIsPending"
  />
</template>
<script>
import { computed } from "vue-demi";
import AccordionRows, { entityRow, entityRowField } from "./AccordionRows.vue";
import { useDocument } from "../../store/document.pinia";
import { useScheduleStation } from "../../store/scheduleStation.pinia";
import { usePartPreSchedule } from "../../store/partPreSchedule.pinia";
import { useScheduleGroup } from "../../store/scheduleGroup.pinia";
import { useGetHelper } from "../../utils/feathers-pinia/use-get-helpers";
import {
  computedRef,
  filterScheduleDocumentsBySpecificId,
} from "../../utils/composables";
import { useFind } from "feathers-pinia";
import { useScheduleDocument } from "../../store/scheduleDocument.pinia";
import { useFindByPartId } from "../../utils/feathers-pinia/use-get-helpers";

export default {
  name: "ManufacturedNotMRPInitAccordion",
  props: {
    node: {
      default: null,
    },
    paneInfo: {
      default: null,
    },
    isPending: {
      default: () => ({}),
    },
  },
  components: {
    AccordionRows,
  },
  setup(props) {
    console.warn("ManufacturedNotMRPInitAccordion.vue");
    const documentStore = useDocument();
    const scheduleStationStore = useScheduleStation();
    const partPreScheduleStore = usePartPreSchedule();
    const scheduleGroupStore = useScheduleGroup();
    const scheduleDocumentStore = useScheduleDocument();

    const node = computed(() => props.node);

    const partPreschedule =
      partPreScheduleStore.getPartPrescheduleFromFlowNode(node);

    const scheduleStationId = computed(
      () => partPreschedule.value?.schedule_station_id ?? null
    );
    const { item: scheduleStationResult, isPending: isPendingScheduleStation } =
      useGetHelper(scheduleStationId, scheduleStationStore);

    const scheduleGroupId = computed(
      () => partPreschedule.value?.schedule_group_id ?? null
    );
    const { item: scheduleGroupResult, isPending: isPendingScheduleGroup } =
      useGetHelper(scheduleGroupId, scheduleGroupStore);

    const {
      items: scheduleDocumentResults,
      isPending: isPendingScheduleDocument,
    } = useFindByPartId(props.node?.part?.id, scheduleDocumentStore);

    const filteredScheduleDocumentResults = filterScheduleDocumentsBySpecificId(
      scheduleDocumentResults,
      scheduleStationId
    );

    const documentIds = computedRef(() => {
      return (
        filteredScheduleDocumentResults.value.result?.map(
          (item) => item.document_id
        ) ?? []
      );
    });
    const documentQuery = computedRef(() => {
      return {
        query: {
          id: { $in: documentIds.value },
          $sort: { name: 1 },
          class: { $in: ["Preschedule", "Flow Kanbans"] },
        },
      };
    });
    const { items: documentResults, isPending: isPendingDocument } = useFind({
      model: documentStore.Model,
      params: documentQuery,
      queryWhen: computed(() => !!documentQuery.value?.query.id.$in.length),
    });

    const currentIsPending = computed(() => {
      return {
        ...props.isPending,
        scheduleStation: isPendingScheduleStation.value,
        scheduleGroup: isPendingScheduleGroup.value,
        partPreSchedule: props.isPending.part,
        document: isPendingDocument.value,
        scheduleDocument: isPendingScheduleDocument.value,
      };
    });

    const entities = computed(() => {
      return {
        scheduleStation: scheduleStationResult.value,
        scheduleGroup: scheduleGroupResult.value,
        partPreSchedule: partPreschedule.value,
        document: documentResults.value,
        scheduleDocument: filteredScheduleDocumentResults.value.result,
      };
    });

    const fieldRows = computed(() => {
      return [
        entityRow("Manufactured, Not MRP Initiation", [
          entityRowField("scheduleStation", "description", {
            label: "Schedule Station",
          }),
          entityRowField("scheduleGroup", "description", {
            label: "Schedule Group",
          }),
          entityRowField("partPreSchedule", "icon", {
            label: "Icon",
          }),
          entityRowField("partPreSchedule", "batch_qty", {
            label: "Batch Quantity",
          }),
          entityRowField("partPreSchedule", "target_qty", {
            label: "Target Quantity",
          }),
          entityRowField("partPreSchedule", "on_hand_qty", {
            label: "On Hand Quantity",
          }),
        ]),
        entityRow(
          "Documents",
          [
            entityRowField("scheduleDocument", null, {
              includeEntities: true,
              type: "documents",
            }),
          ],
          {
            showLabel: true,
          }
        ),
      ];
    });

    return {
      entities,
      fieldRows,
      currentIsPending,
    };
  },
};
</script>
